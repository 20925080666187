import React from "react";
import toLower from "lodash/toLower";
import trim from "lodash/trim";
import queryString from "query-string";
import ReCAPTCHA from "react-google-recaptcha";

import { validateEmail } from "@shared/lib/validateWithYup";

import Button from "@ats/src/components/shared/Button";
import FormInput from "@ats/src/components/forms/FormInput";
import FormContainer from "@ats/src/components/forms/FormContainer";
import Styled from "@ats/src/views/sessions/components/Styled";

import { useMagicLink } from "@shared/queryHooks/useSession";
import { useGetInvite } from "@shared/queryHooks/useInvite";

function AuthForm({
  onComplete,
  location,
  inviteToken,
}: {
  // eslint-disable-next-line no-unused-vars
  onComplete: ({ email }: { email: string }) => void;
  location: any;
  inviteToken?: string;
}) {
  const [state, setState] = React.useState({
    email: "",
    errors: [],
  });
  const path = queryString.parse(location.search).path;
  const redirectPath = path ? path : "/";
  // const inviteToken = queryString.parse(location.search).invite_token;
  const [referral, setReferral] = React.useState(queryString.parse(location.search).referral);
  const recaptchaRef = React.useRef(null);

  const { mutate: magicLink, isLoading: isLoadingMagicLink } = useMagicLink();
  const { data: inviteData, isLoading: isLoadingInvite } = useGetInvite(inviteToken);
  const inviteEmail = inviteData?.email;
  const { email, errors } = state;

  React.useEffect(() => {
    if (inviteEmail != undefined) {
      setState((state) => ({ ...state, email: inviteEmail }));
      setReferral("invite");
    }
  }, [inviteEmail]);

  const handleInputChange = (name, value) => {
    setState({ ...state, [name]: value, errors: null });
  };

  const handleAuth = async (e) => {
    e.preventDefault();

    const [isValid, validationErrors] = await validateEmail({ email });

    let cleanedEmail = trim(toLower(email));

    if (isValid) {
      const recaptchaToken = window.IS_TEST_ENV
            ? null
            : await recaptchaRef.current.executeAsync();
      magicLink(
        {
          email: cleanedEmail,
          organizationSlug: null,
          inviteToken,
          redirectTo: redirectPath,
          referral,
          loginIntent: "hire",
          recaptchaToken: recaptchaToken,
        },
        {
          onSuccess: (data) => {
            window.logger("[AuthForm] data", { ...data });
            onComplete({ email: cleanedEmail, ...data });
          },
          onError: (response: any) => {
            window.logger("[AuthForm] server error", { response });
            // Handles Devise Errors
            if (response.data.error) {
              setState({ ...state, errors: [response.data.error] });
            }
            // Handles non Devise Errors
            if (response.data.errors) {
              setState({ ...state, errors: response.data.errors });
            }
            recaptchaRef?.current?.reset();
          },
        },
      );
    } else {
      window.logger("[AuthForm] errors", { validationErrors });
      setState({ ...state, errors: validationErrors });
    }
  };

  const recaptchaElement = (
    <Styled.RecaptchaContainer>
      <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        sitekey={(window as any).GOOGLE_RECAPTCHA_SITE_KEY}
        className="grecaptcha-badge"
      />
    </Styled.RecaptchaContainer>
  );

  return (
    <FormContainer
      onSubmit={handleAuth}
      errors={errors}
      buttons={
        <Button
          type="submit"
          disabled={isLoadingMagicLink}
          loading={isLoadingMagicLink}
          isTracked={false}
        >
          Continue
        </Button>
      }
    >
      <FormInput
        onChange={handleInputChange}
        name="email"
        placeholder=""
        label="Email"
        className=""
        value={email || ""}
        errors={errors}
        isDisabled={inviteToken != undefined}
      />
      {recaptchaElement}
    </FormContainer>
  );
}

export default AuthForm;
