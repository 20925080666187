import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { Link } from "react-router-dom";
import isEmpty from "lodash/isEmpty";
import Icon from "@ats/src/components/shared/Icon";
import ShortcutKey from "@ats/src/components/shared/ShortcutKey";

import SharedDocumentFeedItem from "@ats/src/views/jobApplications/activities/SharedDocumentFeedItem";
import Button from "@ats/src/components/shared/Button";
import EmptyState from "@ats/src/components/shared/EmptyState";
import CommentListItem from "@ats/src/views/jobApplications/comments/CommentListItem";
import CommentModal from "@ats/src/views/jobApplications/comments/CommentModal";
import ReviewRequestModal from "@ats/src/components/modals/ReviewRequestModal";
import ReviewListItem from "@ats/src/views/reviews/ReviewListItem";
import ActivityListItem from "@ats/src/views/jobApplications/activities/ActivityListItem";
import LoadingIndicator from "@ats/src/components/shared/LoadingIndicator";
import DropdownMenu from "@ats/src/components/shared/DropdownMenu";
import SharedDocumentModal from "@ats/src/components/modals/SharedDocumentModal";
import Tooltip from "@ats/src/components/shared/Tooltip";

import { prettyDateAndTime } from "@shared/lib/time";
import { useJobApplicationActivities } from "@shared/queryHooks/useJobApplicationActivities";
import { useComments } from "@shared/queryHooks/useComment";
import { useModalContext } from "@shared/context/ModalContext";
import { useCurrentSession } from "@ats/src/context/CurrentSessionContext";
import { useHotkeys } from "react-hotkeys-hook";

type Props = {
  jobApplication: any;
  orgAdminJobsListUrl: string;
  // setIsDirty: any;
};
function JobApplicationActivity({ jobApplication, orgAdminJobsListUrl }: Props) {
  // const { jobApplication } = props;
  const { openModal, removeModal } = useModalContext();
  const { currentOrganizationUser, currentOrganization } = useCurrentSession();

  const activityFeedRef = React.useRef(null);

  const {
    data: jobApplicationActivitiesData,
    isLoading: isLoadingJobApplicationActivities,
  } = useJobApplicationActivities({
    jobApplicationId: jobApplication?.id,
  });
  const { data: commentsData, isLoading: isLoadingComments } = useComments({
    jobApplicationId: jobApplication?.id,
  });

  const jobApplicationActivities =
    jobApplicationActivitiesData == undefined ? [] : jobApplicationActivitiesData.items;
  const comments = commentsData == undefined ? [] : commentsData.items;

  const sortedQuestionResponses =
    jobApplication?.questionResponses?.length > 0
      ? jobApplication?.questionResponses?.sort(function (a, b) {
          return (
            a.createdAtTimestamp - b.createdAtTimestamp || a.question.position - b.question.position
          );
        })
      : [];

  const categorizedJobApplicationActivities = jobApplicationActivities.map((activity) => {
    activity["activityCategory"] = "activity";
    return activity;
  });

  const categorizedComments = comments.map((comment) => {
    comment["activityCategory"] = comment.kind;
    return comment;
  });

  const allTheThings = [...categorizedJobApplicationActivities, ...categorizedComments];

  const sortedAllTheThings = allTheThings.sort(function (a, b) {
    return a.publishedAtTimestamp - b.publishedAtTimestamp;
  });

  const scrollToBottom = () => {
    window.logger("%c[JobApplicationActivity] scrollToBottom", "color: #34970c", {
      feedRef: activityFeedRef.current,
    });
    if (activityFeedRef.current) {
      activityFeedRef.current.scrollTop = activityFeedRef.current.scrollHeight;
    }
  };

  const scrollToTop = () => {
    window.logger("%c[JobApplicationActivity] scrollToTop", "color: #a17a0d", {
      feedRef: activityFeedRef.current,
    });
    if (activityFeedRef.current) {
      activityFeedRef.current.scrollTop = 0;
    }
  };

  React.useEffect(() => {
    scrollToBottom();
  }, [
    jobApplicationActivitiesData,
    commentsData,
    isLoadingJobApplicationActivities,
    isLoadingComments,
    // activityFeedRef,
    jobApplication,
  ]); // left out scrollToBottom on purpose

  window.logger("%c[JobApplicationActivity] ", "background: #EFDDEF; color: #1976D2", {
    response: jobApplication.questionResponses,
    sortedQuestionResponses,
    jobApplicationActivities,
    questionResponses: jobApplication?.questionResponses,
    allTheThings,
    sortedAllTheThings,
  });

  const handleOnClickAddComment = (event) => {
    event.preventDefault();
    const modal = (
      <CommentModal
        jobApplicationId={jobApplication?.id}
        comment={{}}
        onCancel={removeModal}
        isEditing={false}
      />
    );

    openModal(modal);
  };

  const handleOnClickRequestReview = (event) => {
    event.preventDefault();
    const modal = (
      <ReviewRequestModal
        onCancel={removeModal}
        organizationUsers={currentOrganization?.organizationUsers}
        jobApplicationId={jobApplication?.id}
      />
    );

    openModal(modal);
  };

  const sharedDocumentModal = (
    <SharedDocumentModal
      jobApplication={jobApplication}
      onCancel={removeModal}
      onSuccess={scrollToTop}
    />
  );

  const handleOpenSharedDocumentModal = (event) => {
    event.preventDefault();

    // window.logger("%c[JobApplicationActivity] handleOpenSharedDocumentModal", "color: #1976D2", {
    //   jobApplication,
    // });

    openModal(sharedDocumentModal);
    scrollToTop();
  };

  /* HOTKEYS
  --===================================================-- */
  useHotkeys(
    "h",
    (event) => {
      event.preventDefault();
      // handleOpenSharedDocumentModal(event);
      openModal(sharedDocumentModal);
      return false;
    },
    {},
    [jobApplication],
  );

  const displayAdminOnly =
    currentOrganizationUser.isAdmin && jobApplication.settings.overview === "private";

  const heading = (
    <Styled.Title>
      <Styled.TitleWrapper>
        <h2>Overview</h2>
        {displayAdminOnly && (
          <Tooltip label="Members can only see their own comments and reviews">
            <Styled.Label key="draft">Admin only</Styled.Label>
          </Tooltip>
        )}
      </Styled.TitleWrapper>
      <Styled.HeaderActions data-testid="overview-menu">
        <DropdownMenu label="Overview options">
          <button onClick={handleOpenSharedDocumentModal}>
            {isEmpty(jobApplication?.sharedDocument)
              ? "Add hiring document"
              : "Edit hiring document"}
            <ShortcutKey>H</ShortcutKey>
          </button>
        </DropdownMenu>
      </Styled.HeaderActions>
    </Styled.Title>
  );

  const responses =
    sortedQuestionResponses.length > 0 ? (
      sortedQuestionResponses.map((questionResponse, index) => {
        const question = questionResponse?.question;
        let response;
        if (question?.kind === "multi_select") {
          response = (
            <Styled.List>
              {questionResponse.responseArray.map((selection) => {
                return <li key={`${selection}-${index}`}>{selection}</li>;
              })}
            </Styled.List>
          );
        } else if (question?.kind === "file_upload") {
          response = (
            <Styled.Response>
              <Styled.DocumentLink
                href={questionResponse.customFileUrl}
                target="_blank"
                rel="noreferrer noopener"
              >
                {questionResponse.customFileFilename}
                <Icon name="download" />
              </Styled.DocumentLink>
            </Styled.Response>
          );
        } else {
          response = <Styled.Response>{questionResponse.body}</Styled.Response>;
        }

        if (question == undefined) return null;

        return (
          <Styled.Content key={questionResponse.id}>
            <Styled.Question>{question.questionText}</Styled.Question>
            {response}
          </Styled.Content>
        );
      })
    ) : (
      <EmptyState
        borderless={true}
        icon="clipboard"
        title="No question responses to show"
        message={
          <>
            Custom questions can be added from the{" "}
            {
              <Link
                to={{
                  pathname: `/jobs/${jobApplication.jobId}/setup/application`,
                  state: { orginalPathname: location.pathname }, // tells about the back button
                }}
              >
                application
              </Link>
            }{" "}
            tab in job setup
          </>
        }
      />
    );

  const jobApplicationReceivedEvent = () => {
    let createdText = "";

    if (jobApplication.createdVia === "created_via_job_board") {
      createdText = "Application received";
    } else {
      const organizationUser = currentOrganization.organizationUsers.find(
        (organizationUser) =>
          organizationUser.id === jobApplication.lastUpdatedByOrganizationUserId,
      );
      const userName = organizationUser ? organizationUser.user.fullName : "Unknown";

      createdText =
        jobApplication.createdVia === "created_via_clone"
          ? `Cloned by ${userName}`
          : jobApplication.createdVia === "created_via_bulk_manual_add"
          ? `Imported by ${userName}`
          : `Manually added by ${userName}`;
    }

    return (
      <>
        <Styled.Event>
          <Styled.Icon>
            <Icon
              name={jobApplication.createdVia === "created_via_job_board" ? "inbox" : "user-plus"}
            />
          </Styled.Icon>
          {createdText}
          <Styled.Seperator>‧</Styled.Seperator>
          <Tooltip label={prettyDateAndTime(jobApplication?.createdAtTimestamp)}>
            <span>{jobApplication?.createdAtTimeAgoShort}</span>
          </Tooltip>
        </Styled.Event>
        {(jobApplication.createdVia === "created_via_job_board" ||
          jobApplication.createdVia === "created_via_clone") && (
          <Styled.QuestionResponses>
            {sortedQuestionResponses.length > 0 && (
              <Styled.Intro>
                <Styled.ResponseIcon>
                  <Icon name="file" />
                </Styled.ResponseIcon>
                Application question responses
              </Styled.Intro>
            )}
            {responses}
          </Styled.QuestionResponses>
        )}
      </>
    );
  };

  const activityFeed = sortedAllTheThings.map((feedItem, index) => {
    const { key, ...activityProps } = feedItem;

    switch (feedItem?.activityCategory) {
      case "activity":
        return (
          <React.Fragment key={`activity-${index}`}>
            <ActivityListItem {...activityProps} activityKey={key} />
          </React.Fragment>
        );
      case "comment":
        return <CommentListItem key={`comment-${index}`} {...feedItem} />;
      case "review":
        return (
          <ReviewListItem
            key={`review-${index}`}
            {...feedItem}
            jobApplication={jobApplication}
            orgAdminJobsListUrl={orgAdminJobsListUrl}
          />
        );
      default:
        return null;
    }
  });

  if (isLoadingJobApplicationActivities || isLoadingComments) {
    return <LoadingIndicator label="Loading..." />;
  }

  return (
    <Styled.Container>
      {heading}
      <Styled.Feed
        ref={activityFeedRef}
        aria-label="List of activities that have occured on this application"
      >
        {!isEmpty(jobApplication?.sharedDocument) &&
        jobApplication?.sharedDocument !== "<p></p>" ? (
          <SharedDocumentFeedItem
            body={jobApplication.sharedDocument}
            openSharedDocumentModal={handleOpenSharedDocumentModal}
          />
        ) : null}

        <Styled.Activities>
          {jobApplicationReceivedEvent()}
          {activityFeed}
        </Styled.Activities>
        <Styled.Actions>
          <Button styleType="secondary" onClick={handleOnClickAddComment}>
            Add a comment
          </Button>
          <Button
            type="internalLink"
            styleType="secondary"
            to={{
              pathname: `/applicants/${jobApplication?.hashId}/reviews/new`,
              state: {
                orginalPathname: location.pathname, // tells ReviewKit about the back button
                orgAdminJobsListUrl: orgAdminJobsListUrl, // tells ReviewKit the current value so it can be persisted
              },
            }}
          >
            Start a review
          </Button>
          <Button styleType="secondary" onClick={handleOnClickRequestReview}>
            Request a review
          </Button>
        </Styled.Actions>
      </Styled.Feed>
    </Styled.Container>
  );
}

JobApplicationActivity.propTypes = {};
JobApplicationActivity.defaultProps = {};

export default JobApplicationActivity;

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

Styled.Container = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: JobApplicationActivity;
    display: flex;
    flex-direction: column;
    height: 100%;
  `;
});

Styled.Title = styled.div((props: any) => {
  const t: any = props.theme;
  return css`
    label: JobApplicationMessages_Title;
    ${[t.pt(4), t.pb(4), t.px(4), t.text.h2]}
    border-bottom: 1px solid ${t.dark ? t.color.gray[800] : t.color.gray[200]};
    color: ${t.dark ? t.color.gray[200] : t.color.black};
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
  `;
});

Styled.TitleWrapper = styled.div((props: any) => {
  const t: any = props.theme;
  return css`
    label: JobApplicationMessages_TitleWrapper;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
  `;
});

Styled.HeaderActions = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: JobApplicationActivity_HeaderActions;
    position: absolute;
    right: ${t.spacing[4]};
    display: flex;
    align-items: center;
  `;
});

Styled.Feed = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: JobApplicationActivity_Feed;
    ${[t.p(4)]}
    flex-grow: 1;
    overflow-y: auto;
    position: relative;
  `;
});

Styled.Activities = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: JobApplicationActivity_Activities;
    ${[t.mb(1)]}
    color: ${t.dark ? t.color.gray[400] : t.color.gray[600]};
  `;
});

Styled.QuestionResponses = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: JobApplicationActivity_QuestionResponses;
    ${[t.rounded.md, t.px(4), t.mt(4), t.mb(4)]}
    border: 1px solid ${t.dark ? t.color.gray[800] : t.color.gray[200]};
    background-color: ${t.dark ? t.color.gray[800] : t.color.white};
    position: relative;

    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 100%;
      left: 1.75rem;
      width: 4px;
      margin-left: -2px;
      height: ${t.spacing[6]};
      background-color: ${t.dark ? t.color.gray[800] : t.color.gray[200]};
    }
  `;
});

Styled.ResponseIcon = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: JobApplicationActivity_ResponseIcon;
    ${[t.mr(2), t.h(6), t.w(6), t.rounded.xs]}
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${t.dark ? t.color.gray[800] : t.color.white};
    border: 1px solid ${t.dark ? t.color.gray[700] : t.color.gray[200]};
    color: ${t.dark ? t.color.gray[400] : t.color.gray[600]};
  `;
});

Styled.Intro = styled.div((props: any) => {
  const t: any = props.theme;
  return css`
    label: JobApplicationActivity_Intro;
    ${[t.mt(4), t.mb(3), t.text.xs]}
    color: ${t.dark ? t.color.gray[400] : t.color.gray[600]};
    display: flex;
    align-items: center;
  `;
});

Styled.Icon = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: JobApplicationActivity_Icon;
    ${[t.mr(2), t.ml(3), t.h(6), t.w(6), t.rounded.xs]}
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${t.dark ? t.color.gray[800] : t.color.white};
    border: 1px solid ${t.dark ? t.color.gray[800] : t.color.gray[200]};
    color: ${t.dark ? t.color.gray[400] : t.color.gray[600]};
  `;
});

Styled.Content = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: JobApplicationActivity_Application;
    line-height: 1.6;
    &:first-of-type {
      ${[t.pt(4)]}
    }
    &:last-of-type {
      ${[t.mb(4)]}
    }
  `;
});

Styled.Question = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: JobApplicationActivity_Question;
    ${[t.text.bold, t.text.xs, t.mb(1)]}
    line-height: 1.6;
    color: ${t.dark ? t.color.gray[300] : t.color.black};
  `;
});

Styled.Response = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: JobApplicationActivity_Response;
    ${[t.mb(3)]}
    line-height: 1.6;
    white-space: pre-wrap;
    color: ${t.dark ? t.color.gray[200] : t.color.black};
  `;
});

Styled.DocumentLink = styled.a((props) => {
  const t: any = props.theme;
  return css`
    label: JobApplicationActivity_DocumentLink;
    display: flex;
    align-items: center;
    &:link,
    &:visited {
      color: ${t.dark ? t.color.gray[200] : t.color.black};
    }
    &:hover {
      text-decoration: underline;
    }
    > svg {
      margin-left: 0.375rem;
      height: 1.125em;
      width: 1.125em;
      color: ${t.dark ? t.color.gray[300] : t.color.black};
    }
  `;
});

Styled.Actions = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: JobApplicationActivity_Actions;
    ${[t.px(4), t.pb(4), t.pt(2), t.mt(4), t.rounded.md]}
    background-color: ${t.dark ? t.color.gray[800] : t.color.white};
    border: 1px solid ${t.dark ? t.color.gray[800] : t.color.gray[200]};
    position: relative;
    display: flex;
    flex-wrap: wrap;
    z-index: 1;

    > button,
    > a {
      ${[t.mr(3), t.mt(2)]}
    }

    button:last-of-type {
      ${t.mr(0)}
    }
  `;
});

Styled.Event = styled.div((props: any) => {
  const t: any = props.theme;
  return css`
    label: JobApplicationActivity_Event;
    ${[t.ml(1), t.mb(3), t.text.xs]}
    color: ${t.dark ? t.color.gray[400] : t.color.gray[600]};
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;

    &:after {
      content: "";
      display: block;
      position: absolute;
      left: ${t.spacing[6]};
      top: 100%;
      width: 4px;
      margin-left: -2px;
      height: ${t.spacing[6]};
      background-color: ${t.dark ? t.color.gray[800] : t.color.gray[200]};
    }
  `;
});

Styled.Seperator = styled.span((props) => {
  const t: any = props.theme;
  return css`
    label: JobApplicationActivity_Seperator;
    ${t.mx(1)}
  `;
});

Styled.Label = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: JobApplicationActivity_Label;
    ${[t.mx(3), t.h(6), t.px(2), t.text.xs, t.rounded.xs, t.text.normal]}
    display: inline-flex;
    align-items: center;
    border: 1px solid ${t.dark ? t.color.gray[800] : t.color.gray[200]};
    color: ${t.dark ? t.color.gray[400] : t.color.gray[600]};
    line-height: 1.4;
  `;
});

Styled.List = styled.ul((props) => {
  const t: any = props.theme;
  return css`
    label: QuestionListItem_AnswerList;
    ${[t.ml(6), t.mt(1), t.mb(3)]}
    line-height: 1.6;
    white-space: pre-wrap;
    color: ${t.dark ? t.color.gray[200] : t.color.black};
    list-style-type: circle;

    li {
      ${[t.pt(1), t.pb("px")]}

      &:last-of-type {
        ${t.pb(0)}
      }
    }
  `;
});
